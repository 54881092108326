'use client';

import {
  type RowData,
  type RowSelectionState,
  type TableOptions,
  type Updater,
  type VisibilityState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import type { Route } from 'next';
import { useState } from 'react';

import type { Optional } from '@carbonfact/shared/src/utils/Optional';
import ExportTableButton from '../../primitives/ExportTableButton';
import TableFilters, {
  type AvailableFilters,
  type TableFiltersProps,
} from '../../primitives/TableFilters';
import TableFooter from '../../primitives/TableFooter';
import TableHeaders from '../../primitives/TableHeaders';
import TableLoadingSkeleton from '../../primitives/TableLoadingSkeleton';
import TablePaginationControls from '../../primitives/TablePaginationControls';
import TableRows from '../../primitives/TableRows';
import TableSearch, {
  type TableSearchProps,
} from '../../primitives/TableSearch';

// Allow using column def meta.className to override cell classes for the entire column,
// for instance to align the cells and the header to the right
// https://github.com/TanStack/table/discussions/4097#discussioncomment-3080938
declare module '@tanstack/table-core' {
  // We need to keep the same generic signature as the original interface
  interface ColumnMeta<TData extends RowData, TValue> {
    className?: string;
  }
}

/*
 * TECHNICAL-DEBT
 * TODO: Split the props into imported interfaces from the primitives that actually use them
 */
export type TableProps<
  RowData extends object,
  PossibleRouteType extends string,
  Filters extends AvailableFilters,
> = {
  // Core functionality
  data: RowData[];
  columns: TableOptions<RowData>['columns'];
  hiddenColumns?: (keyof RowData)[];
  // Fetching state
  isLoading?: boolean;
  hasSearch?: boolean;
  scrollHorizontally?: boolean;
  // Search
} & TableSearchProps & {
    // Filters
  } & Optional<TableFiltersProps<Filters>, 'availableFilters'> & {
    // Row interactions
    onRowClick?: (row: RowData) => void;
    rowHref?: (row: RowData) => Route<PossibleRouteType> | null;
    // Row selection
    selectedRows?: RowSelectionState | null;
    setSelectedRows?:
      | ((selectedRows: Updater<RowSelectionState>) => void)
      | null;
    onRowSelectionChange?: (selectedRows: Updater<RowSelectionState>) => void;
  } & {
    // Pagination
    hasPagination?: boolean;
    serverSideRowPagesIterator?: (
      pageSize: number,
    ) => AsyncGenerator<RowData[], void, unknown>;
  } & {
    // Additional features
    exportable?: boolean;
  } & {
    // Pass initial state for sorting, pagination, pinning...
    initialTableState?: Partial<TableOptions<RowData>['initialState']>;
    // Allow overriding controls to ReactTable, useful for e.g manual pagination state handling
    customReactTableOptions?: Partial<TableOptions<RowData>>;
  };

export function Table<
  RowData extends object,
  PossibleRouteType extends string,
  Filters extends AvailableFilters,
>({
  selectedRows = null,
  setSelectedRows = null,
  scrollHorizontally = false,
  ...props
}: TableProps<RowData, PossibleRouteType, Filters>) {
  const [selectedRowsInternal, setSelectedRowsInternal] =
    selectedRows !== null && setSelectedRows !== null
      ? [selectedRows, setSelectedRows]
      : useState<RowSelectionState>({});

  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    defaultColumn: {
      size: 0, // Default to auto
    },

    // enable react-table features
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => (row as { subRows?: [] }).subRows,
    filterFromLeafRows: true,

    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 25,
      },
      ...props.initialTableState,
    },
    state: {
      rowSelection: selectedRowsInternal,
      columnVisibility: props.hiddenColumns?.reduce<VisibilityState>(
        (acc, columnId) => {
          acc[columnId as string] = false;
          return acc;
        },
        {}, // No `as` needed here
      ),
    },

    onRowSelectionChange: (selection) => {
      setSelectedRowsInternal(selection);
      if (props.onRowSelectionChange) {
        props.onRowSelectionChange(selection);
      }
    },

    ...props.customReactTableOptions,
  });

  return (
    <div className={classNames(scrollHorizontally ? 'w-full' : '')}>
      {/* Search & filtering */}
      {(props.hasSearch || props.availableFilters || props.exportable) && (
        <div className="flex flex-row gap-3 justify-start items-center py-3">
          {props.hasSearch && (
            <TableSearch
              table={table}
              searchFilter={props.searchFilter}
              onSearchFilterChange={props.onSearchFilterChange}
            />
          )}

          {props.availableFilters && (
            <TableFilters
              table={table}
              availableFilters={props.availableFilters}
              selectedFilters={props.selectedFilters}
              onFiltersChange={props.onFiltersChange}
            />
          )}

          <div className="grow" />

          {props.exportable && (
            <ExportTableButton
              table={table}
              serverSideRowPagesIterator={props.serverSideRowPagesIterator}
            />
          )}
        </div>
      )}
      {/* Table content */}
      <div
        className={classNames(
          'w-full',
          scrollHorizontally ? 'overflow-x-auto' : 'overflow-y-visible',
        )}
      >
        <table className="table w-full border-b border-gray-200 text-sm">
          <TableHeaders table={table} />
          <tbody className="border-separate border-t border-gray-200">
            {props.isLoading ? (
              <TableLoadingSkeleton table={table} />
            ) : (
              <TableRows
                table={table}
                onRowClick={props.onRowClick}
                rowHref={props.rowHref}
              />
            )}
          </tbody>
          <TableFooter table={table} />
        </table>
      </div>

      {/* Pagination */}
      {(props.hasPagination ??
        table.getPrePaginationRowModel().flatRows.length > 10) && (
        <TablePaginationControls table={table} isLoading={props.isLoading} />
      )}
    </div>
  );
}
