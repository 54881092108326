import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';
import type { Table } from '@tanstack/react-table';
import { useTranslations } from 'next-intl';

export default function TablePaginationControls<T>({
  table,
  isLoading,
}: {
  table: Table<T>;
  isLoading?: boolean;
}) {
  const t = useTranslations();
  const { pageIndex, pageSize } = table.getState().pagination;
  const pageCount = table.getPageCount();
  const totalRows = table.getRowCount();
  const currentPageNumber = pageIndex + 1;
  // Add +1 to go from index to count
  const pageRowIndexStart = pageSize * pageIndex + 1;
  const pageRowIndexEnd = Math.min(pageSize * currentPageNumber, totalRows);

  return (
    <div className="flex items-center p-2 text-sm">
      <div className="grow text-gray-600">
        {isLoading ? (
          t('Table.Controls.loadingEntries')
        ) : (
          <>
            {totalRows === 0
              ? t('Table.Controls.noEntries')
              : t('Table.Controls.pagination.showing', {
                  start: pageRowIndexStart,
                  end: pageRowIndexEnd,
                  total: totalRows,
                })}
          </>
        )}
      </div>
      <div>
        <span className="text-gray-700">{t('Table.Controls.show')}</span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
          className="border-none text-sm font-medium text-gray-700"
        >
          {[10, 25, 50, 100, 200].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize} {t('Table.rows')}
            </option>
          ))}
        </select>
      </div>

      <button
        className="rounded-sm p-1 disabled:text-gray-300"
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
      >
        <ChevronDoubleLeftIcon className="h-5 w-5 " />
      </button>
      <button
        className="rounded-sm p-1 disabled:text-gray-300"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        <ChevronLeftIcon className="h-5 w-5" />
      </button>
      {pageCount > 1 && (
        <div>
          <span className="text-gray-600">
            {currentPageNumber}/{pageCount}
          </span>
        </div>
      )}
      <button
        className="rounded-sm p-1 disabled:text-gray-300"
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        <ChevronRightIcon className="h-5 w-5" />
      </button>
      <button
        className="rounded-sm p-1 disabled:text-gray-300"
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
      >
        <ChevronDoubleRightIcon className="h-5 w-5" />
      </button>
    </div>
  );
}
