import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/20/solid';
import { type Table, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';

export default function TableHeaders<T>({ table }: { table: Table<T> }) {
  return (
    <thead>
      {
        // Loop over the header rows
        table
          .getHeaderGroups()
          .map((headerGroup) => (
            // Apply the header row props
            <tr
              key={headerGroup.id}
              className="text-carbon-600 font-extrabold h-9 border-b border-gray-200"
            >
              {
                // Loop over the headers in each row
                headerGroup.headers.map((header) => (
                  // Apply the header cell props
                  <th
                    key={header.id}
                    className={classNames(
                      'table-cell whitespace-nowrap p-2',
                      header.subHeaders.length >= 1
                        ? 'justify-center text-center'
                        : 'text-left',
                      header.column.columnDef.meta?.className,
                      header.column.getCanSort()
                        ? 'cursor-pointer'
                        : 'cursor-default',
                      // Pinned columns
                      header.column.getIsPinned() === 'left'
                        ? 'bg-white h-9 sticky left-0'
                        : '',
                      header.column.getIsPinned() === 'right'
                        ? 'bg-white h-9 sticky right-0'
                        : '',
                    )}
                    colSpan={header.colSpan}
                    style={{
                      width: header.column.columnDef.size
                        ? header.column.getSize()
                        : 'auto',
                    }}
                  >
                    {header.column.getIsPinned() && (
                      <div
                        className={classNames(
                          'absolute inset-0 border-gray-300',
                          header.column.getIsPinned() === 'right'
                            ? 'border-l'
                            : 'border-r',
                        )}
                      />
                    )}
                    <div
                      onClick={header.column.getToggleSortingHandler()}
                      className={classNames(
                        'w-full flex flex-row items-center gap-2 grow font-medium',
                        header.subHeaders.length >= 1
                          ? 'justify-center text-center'
                          : 'text-left',
                        header.column.columnDef.meta?.className,
                        header.column.getCanSort()
                          ? 'cursor-pointer'
                          : 'cursor-default',
                      )}
                    >
                      {{
                        asc: <BarsArrowUpIcon className="inline h-4 w-4" />,
                        desc: <BarsArrowDownIcon className="inline h-4 w-4" />,
                      }[header.column.getIsSorted() as string] ?? null}
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </div>
                  </th>
                ))
              }
            </tr>
          ))
      }
    </thead>
  );
}
