import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import classNames from 'classnames';
import type React from 'react';

type ClickableDropdownProps = {
  disclosure: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  top?: string;
};
export default function ClickableDropdown({
  disclosure,
  children,
  className,
  top = 'top-7',
}: ClickableDropdownProps) {
  return (
    <Popover className="flex flex-row items-center">
      <PopoverButton as="div" className="focus:outline-hidden cursor-pointer">
        {disclosure}
      </PopoverButton>
      <PopoverPanel
        anchor="bottom start"
        className={classNames(
          className,
          `z-50 bg-white ${top} rounded-md shadow-md border border-gray-200 overflow-hidden`,
        )}
      >
        {children}
      </PopoverPanel>
    </Popover>
  );
}
